import React, { useEffect } from 'react';
import { planPrices } from '../../data/data';
import { FcCheckmark } from 'react-icons/fc';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../context/context';

const UpgradeAccount = () => {
  const { resetPage } = useGlobalContext();

  useEffect(() => {
    resetPage(`Upgrade Account`);
    // eslint-disable-next-line
  }, []);

  return (
    <section className='upgrade-account'>
      <div className='prices-cards'>
        {planPrices.map((plan, index) => {
          const { planName, planPrice, planFeatures } = plan;
          return (
            <div className='card' key={index}>
              <h3>{planName}</h3>
              <h3 className='price'>{planPrice}</h3>
              <p className='feature-comp'>Top feature comparison</p>
              <ul className='features-list'>
                {planFeatures.map((feature, index) => {
                  return (
                    <li key={index}>
                      <span>
                        <FcCheckmark />
                      </span>{' '}
                      {feature}
                    </li>
                  );
                })}
              </ul>

              <Link to='/dashboard/deposit'>
                <button className='purple'>Upgrade Plan</button>
              </Link>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default UpgradeAccount;
